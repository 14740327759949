.lang-container {
    margin-left: 20px;
    padding: 10px;
}

.global {
    cursor: pointer;
}

.lang {
    position: relative;
    display: inline-block;
}

/* lang Content (Hidden by Default) */
.lang-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 130px;
    margin-top: 10px;
    right: 0;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 99;
}

/* Links inside the lang */
.lang-content .lang-item {
    cursor: pointer;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

/* Change color of lang links on hover */
.lang-content .lang-item:hover {
    background-color: #f1f1f1;
}

/* Show the lang menu on hover */
.lang-container:hover .lang-content {
    display: block;
}

/* Change the background color of the lang button when the lang content is shown */
.lang:hover .dropbtn {
    background-color: #3e8e41;
}

@media (max-width: 1199px) {
    .lang-container {
        position: absolute;
        right: 45px;
    }
}
